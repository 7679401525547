@charset "UTF-8";
/*🤫
🤫
🤫
🤫
🤫*/
.or_minis {
  box-sizing: border-box;
  text-align: center;
  height: 40px;
  position: relative;
  text-decoration: none !important;
  display: block;
  padding-top: 4px;
  cursor: pointer;
  width: 52px;
}
.or_minis .or_minis__badge {
  position: absolute;
  top: 13px;
  left: 28px;
}
@media screen and (min-width: 48em) {
  .or_minis .or_minis__badge {
    top: 13px;
    left: 32px;
  }
}
.or_minis .or_minis__badge--empty {
  display: none;
}
.or_minis .or_minis__icon {
  width: 50px;
  height: 28px;
  margin-top: -3px;
  fill: #777777;
}
@media screen and (min-width: 48em) {
  .or_minis .or_minis__icon {
    width: 65px;
    height: 28px;
    margin-top: 0;
  }
}
@media screen and (min-width: 48em) {
  .or_minis {
    width: 62px;
    padding-top: 0;
  }
}
.or_minis .or_minis__text {
  color: #777777;
  left: 0;
  position: absolute;
  top: 26px;
}
@media screen and (min-width: 48em) {
  .or_minis .or_minis__text {
    display: none;
  }
}
.or_minis .or_minis__text--large {
  color: #777777;
  position: absolute;
  left: 0;
  top: 27px;
}
@media screen and (max-width: calc(48em - 1px)) {
  .or_minis .or_minis__text--large {
    display: none;
  }
}

/*🤫*/