@use "@otto-ec/global-frontend-settings/all" as gfs;

.or_minis {
  box-sizing: border-box;
  text-align: center;
  height: 40px;
  position: relative;
  text-decoration: none !important; /*🤫*/
  display: block;
  padding-top: 4px;
  cursor: pointer;
  width: 52px;

  .or_minis__badge {
    position: absolute;
    top: 13px;
    left: 28px;
    @media screen and (min-width: gfs.$large-width) {
      top: 13px;
      left: 32px;
    }
  }

  .or_minis__badge--empty {
    display: none;
  }

  .or_minis__icon {
    width: 50px;
    height: 28px;
    margin-top: -3px;
    fill: gfs.$pl_color-grey400;
    @media screen and (min-width: gfs.$large-width) {
      width: 65px;
      height: 28px;
      margin-top: 0;
    }
  }

  @media screen and (min-width: gfs.$large-width) {
    width: 62px;
    padding-top: 0;
  }

  .or_minis__text {
    color: gfs.$pl_color-grey400;
    left: 0;
    position: absolute;
    top: 26px;
    @media screen and (min-width: gfs.$large-width) {
      display: none;
    }
  }

  .or_minis__text--large {
    color: gfs.$pl_color-grey400;
    position: absolute;
    left: 0;
    top: 27px;
    @media screen and (max-width: calc(#{gfs.$large-width} - 1px)) {
      display: none;
    }
  }
}