/*🤫
🤫
🤫
🤫
🤫*/

$pl_border-radius10: 2px;
$pl_border-radius25: 4px;
$pl_border-radius50: 8px;
$pl_border-radius100: 16px;

$pl_color-success: #50cc7f;
$pl_color-sustainable: #7ea87b;
$pl_color-hint: #ffdf33;
$pl_color-warm-red: #8C2D4B;
$pl_color-deep-navy: #1E5A78;
$pl_color-red100: #f00020;
$pl_color-blue100: #008ffd;
$pl_color-white100: #ffffff;
$pl_color-black100: #222222;
$pl_color-grey25: #f0f0f0;
$pl_color-grey100: #e6e6e6;
$pl_color-grey200: #c4c4c4;
$pl_color-grey400: #777777;

$pl_margin25: 4px;
$pl_margin50: 8px;
$pl_margin75: 12px;
$pl_margin100: 16px;
$pl_margin150: 24px;
$pl_margin175: 32px;
$pl_margin200: 40px;
$pl_margin250: 64px;
$pl_margin300: 80px;

$pl_padding25: 4px;
$pl_padding50: 8px;
$pl_padding75: 12px;
$pl_padding100: 16px;
$pl_padding150: 24px;
$pl_padding175: 32px;
$pl_padding200: 40px;
$pl_padding250: 64px;
$pl_padding300: 80px;

$pl_display100: (
font-size: 26px,
line-height: 32px,
font-weight: bold,
);
$pl_display200: (
font-size: 64px,
line-height: 64px,
font-weight: bold,
);
$pl_headline10: (
font-size: 10px,
line-height: 16px,
font-weight: bold,
);
$pl_headline25: (
font-size: 12px,
line-height: 16px,
font-weight: bold,
);
$pl_headline50: (
font-size: 14px,
line-height: 24px,
font-weight: bold,
);
$pl_headline100: (
font-size: 16px,
line-height: 24px,
font-weight: bold,
);
$pl_headline200: (
font-size: 20px,
line-height: 32px,
font-weight: bold,
);
$pl_headline300: (
font-size: 24px,
line-height: 32px,
font-weight: bold,
);
$pl_copy50: (
font-size: 10px,
line-height: 16px,
);
$pl_copy75: (
font-size: 12px,
line-height: 16px,
);
$pl_copy100: (
font-size: 14px,
line-height: 24px,
);
$pl_copy125: (
font-size: 16px,
line-height: 24px,
);
